import React from "react"

import bio from "../bio"
import Layout, { LayoutContext } from "../components/layout"
import renderHTML from "react-render-html"
import SEO from "../components/seo"
import Typography from "../components/typography"

import "./work-with-us.css"
import Button from "../components/button"

const columns = [
  {
    icon: require("../images/icons/col01.svg"),
    header: "An honest voice",
    desc:
      "We create pay-off plans just for you, not for someone like you. <br /><br /> Many advisors and lenders provide advice applicable to a groups of people with similar incomes, needs, and debt levels.  Our personalized plans are custom-tailored based upon your specific financial goals and current situation.",
  },
  {
    icon: require("../images/icons/col02.svg"),
    header: "An expert approach",
    desc:
      "Our specialty is creating student loan pay-off plans that emphasize living your life in the moment while planning for your future.<br /><br />The financial services world is vast.  Our expert advice is a product of years of experience, higher learning, and continual education.",
  },
  {
    icon: require("../images/icons/col03.svg"),
    header: "A custom solution",
    desc:
      "Many lenders and advisors focus solely on your student loan pay-off and neglect your other financial goals.<br /><br />We take a comprehensive approach when developing your pay-off plan.  We keep your current needs in focus while looking towards your short-term and long-term goals.  This method gives you the highest probability of achieving all of your goals. ",
  },
]

const ColumnSection = () => {
  return (
    <section className="work__columns page-wrapper">
      {columns.map(col => (
        <div className="work__column">
          <div className="work__col-container">
            <img src={col.icon} alt="icon" />
            <Typography variant="h3">{col.header}</Typography>
            <Typography variant="body1">{renderHTML(col.desc)}</Typography>
          </div>
        </div>
      ))}
    </section>
  )
}

const Bio = () => {
  const { toggleForm } = React.useContext(LayoutContext)

  const handleClick = () => {
    toggleForm(true)
  }

  return (
    <section className="bio page-wrapper">
      <div className="bio__content">{renderHTML(bio)}</div>
      <div className="bio__image-container">
        <img
          src={require("../images/bio.png")}
          alt="colin mohanyan"
          className="bio__img"
        />
        <div className="colin__wrapper">
          <div className="colin">Colin Moynahan, CFP®</div>

          <Button onClick={handleClick} variant="primary">
            Work With Us
          </Button>
        </div>
      </div>
    </section>
  )
}

const IndexPage = ({ data: { meta } }) => {
  return (
    <Layout>
      <SEO
        slug={meta.slug}
        title={meta.yoast_meta.yoast_wpseo_title}
        description={meta.yoast_meta.yoast_wpseo_metadesc}
      />
      <section className="work__hero ">
        <div className="work__container page-wrapper">
          <div className="work__content">
            <Typography variant="h1">
              We're creating pay-off plans for you, not for someone like you.
            </Typography>
          </div>
        </div>
        <img
          src={require("../images/hero-bg.svg")}
          alt=""
          className="work__hero-bg"
        />
      </section>
      <Bio />
      <ColumnSection />
      <section className="work__two-col two-col img-left page-wrapper">
        <div className="work__img-container">
          <img
            src={require("../images/home-01.png")}
            alt=""
            className="work__img-one"
          />
          <img
            src={require("../images/home-01-bg.svg")}
            alt="Grey bg"
            className="work__img-bg img-bg--one"
          />
        </div>
        <div className="work__content-container">
          <Typography variant="h2">
            Don't put life on pause, your goals are achievable.
          </Typography>
          <Typography
            variant="body1"
            style={{ opacity: 0.6, marginTop: 12, marginBottom: 32 }}
          >
            Student loans shouldn't take over your life, we create personalized
            plans that make your loans a thing of the past while living in the
            present and planning for the future.
          </Typography>
          <Button variant="primary" to="/how-it-works">
            Learn More
          </Button>
        </div>
      </section>
    </Layout>
  )
}

export default IndexPage

export const query = graphql`
  query {
    meta: wordpressPage(slug: { eq: "work-with-us" }) {
      slug
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
        yoast_wpseo_canonical
      }
    }
  }
`
