const bio = `<p>Student loan debt is at an all-time high in the United States.</p>
<h2>
  45 million borrowers collectively owe more than $1.5 trillion in student
  loans.
</h2>
<p>
  Over the past 50 years, public tuition costs have more than doubled while
  wages have increased just under 68%. On top of wages that are not keeping pace
  with tuition, college graduates today face increased comparable housing prices
  and higher costs of living. This is pushing borrowers across the country to
  delay milestone events – buying homes, having children, and even getting
  married.
</p>
<p>The repayment options for paying off student loans are endless.</p>
<p>
  Navigating both the Federal and Private markets is a confusing and complicated
  process. The sheer thought of attempting to figure it out on their own is
  enough to deter many from even trying.
</p>
<h2>We navigate it for you and turn confusion into order.</h2>
<p>
  Our plans begin with analyzing your current finances and defining your life
  goals. Once we build your foundation, we deliver a plan structured to give you
  the highest probability of achieving your goals, improving your quality of
  life, and increasing your net worth.
</p>
`

export default bio
